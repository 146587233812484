import { apClient } from "@/app/core/gQL/apolloClient";
import {
  GetTaskBoardDocument,
  GetTaskBoardQuery,
  GetTaskBoardQueryVariables,
  GetTasksDocument,
  GetTaskSheetDocument,
  GetTaskSheetQuery,
  GetTaskSheetQueryVariables,
  GetTasksQuery,
  GetTasksQueryVariables,
} from "@/shared/types/schema.types";

export async function fetchTasks(
  params: GetTaskSheetQueryVariables,
): Promise<GetTaskSheetQuery["taskSheet"]> {
  const result = await apClient.get<GetTaskSheetQuery>(GetTaskSheetDocument, { variables: params });
  return result.taskSheet;
}

export async function fetchKanbanTasks(
  params: GetTaskBoardQueryVariables,
): Promise<GetTaskBoardQuery["taskBoard"]> {
  const result = await apClient.get<GetTaskBoardQuery>(GetTaskBoardDocument, { variables: params });
  return result.taskBoard;
}

export async function fetchCalendarTasks(
  params: GetTasksQueryVariables,
): Promise<GetTasksQuery["tasks"]> {
  const result = await apClient.get<GetTasksQuery>(GetTasksDocument, { variables: params });
  return result.tasks;
}
