import { apClient } from "@/app/core/gQL/apolloClient";
import {
  ArchiveProjectDocument,
  ArchiveProjectQuery,
  ArchiveProjectQueryVariables,
  GetProjectCalendarTasksDocument,
  GetProjectCalendarTasksQuery,
  GetProjectCalendarTasksQueryVariables,
  GetProjectTaskboardDocument,
  GetProjectTaskboardQuery,
  GetProjectTaskboardQueryVariables,
  GetProjectTaskSheetDocument,
  GetProjectTaskSheetQuery,
  GetProjectTaskSheetQueryVariables,
  RemoveProjectDocument,
  RemoveProjectQuery,
  RemoveProjectQueryVariables,
  TogglePinProjectDocument,
  TogglePinProjectQuery,
  TogglePinProjectQueryVariables,
  UnzipProjectDocument,
  UnzipProjectQuery,
  UnzipProjectQueryVariables,
  UpdateProjectResultDocument,
  UpdateProjectResultQuery,
  UpdateProjectResultQueryVariables,
  UpdateProjectStatusDocument,
  UpdateProjectStatusQuery,
  UpdateProjectStatusQueryVariables,
} from "@/shared/types/schema.types";

export async function fetchProjectTasks(
  params: GetProjectTaskSheetQueryVariables,
): Promise<GetProjectTaskSheetQuery["project"]["taskSheet"]> {
  const result = await apClient.get<GetProjectTaskSheetQuery>(GetProjectTaskSheetDocument, {
    variables: params,
  });
  return result.project.taskSheet;
}

export async function fetchProjectKanbanTasks(
  params: GetProjectTaskboardQueryVariables,
): Promise<GetProjectTaskboardQuery["project"]["taskBoard"]> {
  const result = await apClient.get<GetProjectTaskboardQuery>(GetProjectTaskboardDocument, {
    variables: params,
  });
  return result.project.taskBoard;
}

export async function fetchProjectCalendarTasks(
  params: GetProjectCalendarTasksQueryVariables,
): Promise<GetProjectCalendarTasksQuery["project"]["tasks"]> {
  const result = await apClient.get<GetProjectCalendarTasksQuery>(GetProjectCalendarTasksDocument, {
    variables: params,
  });
  return result.project.tasks;
}

export async function updateProjectStatus(
  params: UpdateProjectStatusQueryVariables,
): Promise<UpdateProjectStatusQuery["project"]["setStatus"]> {
  const {
    project: { setStatus },
  } = await apClient.set<UpdateProjectStatusQuery, UpdateProjectStatusQueryVariables>(
    UpdateProjectStatusDocument,
    params,
  );
  return setStatus;
}

export async function archiveProject(
  params: ArchiveProjectQueryVariables,
): Promise<ArchiveProjectQuery["project"]["archive"]> {
  const {
    project: { archive },
  } = await apClient.set<ArchiveProjectQuery, ArchiveProjectQueryVariables>(
    ArchiveProjectDocument,
    params,
  );
  return archive;
}

export async function unzipProject(
  params: UnzipProjectQueryVariables,
): Promise<UnzipProjectQuery["project"]["unzip"]> {
  const {
    project: { unzip },
  } = await apClient.set<UnzipProjectQuery, UnzipProjectQueryVariables>(
    UnzipProjectDocument,
    params,
  );
  return unzip;
}

export async function removeProject(
  params: RemoveProjectQueryVariables,
): Promise<RemoveProjectQuery["project"]["remove"]> {
  const {
    project: { remove },
  } = await apClient.set<RemoveProjectQuery, RemoveProjectQueryVariables>(
    RemoveProjectDocument,
    params,
  );
  return remove;
}

export async function togglePinProject(
  params: TogglePinProjectQueryVariables,
): Promise<RemoveProjectQuery["project"]["remove"]> {
  const {
    project: { invertPinned },
  } = await apClient.set<TogglePinProjectQuery, TogglePinProjectQueryVariables>(
    TogglePinProjectDocument,
    params,
  );
  return invertPinned;
}

export async function updateProjectResult(
  params: UpdateProjectResultQueryVariables,
): Promise<UpdateProjectResultQuery["project"]["updateResult"]> {
  const {
    project: { updateResult },
  } = await apClient.set<UpdateProjectResultQuery, UpdateProjectResultQueryVariables>(
    UpdateProjectResultDocument,
    params,
  );
  return updateResult;
}
