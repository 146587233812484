










































































import { defineComponent, PropType } from "@vue/composition-api";

import IconClose from "@/app/components/Icons/IconClose.vue";
import { ModalPosition } from "@/shared/enums/components/Base/modal/Modal.enums";
import { useModalsLayout } from "@/shared/hooks/modal";

export default defineComponent({
  name: "Modal",
  components: { IconClose },
  props: {
    additionalClass: {
      type: String,
      default: "", 
    },
    title: {
      type: String,
      default: null, 
    },
    showHeader: {
      type: Boolean,
      default: true, 
    },
    position: {
      type: String as PropType<ModalPosition>,
      default: ModalPosition.FULLSCREEN_CENTER,
    },
    modalSize: {
      type: String,
      default: "", 
    },
    showActions: {
      type: Boolean,
      default: true, 
    },
    closeAway: {
      type: Boolean,
      default: true, 
    }, // @DOC: закрывать ли модальку, если кликнули вне неё
  },

  setup(_, { emit }) {
    const { modals, addModal, removeModal } = useModalsLayout();

    function onClose() {
      emit("close");
    }

    return {
      modals,
      addModal,
      removeModal,
      onClose,
    };
  },


  data() {
    return { clickedOutside: false };
  },

  computed: {
    positionClass() {
      switch (this.position) {
        case ModalPosition.LEFT:
          return "modal--left";
        case ModalPosition.LEFT_TOP:
          return "modal--left-top";
        case ModalPosition.RIGHT:
          return "modal--right";
        case ModalPosition.FULLSCREEN_CENTER:
          return "modal--fullscreen-center";
        default:
          return null;
      }
    },
    modalName() {
      return `w-modal-${_.uniqueId()}`;
    },

    bodyElement() {
      return document.querySelector('body');
    },
  },

  created() {
    this.bodyElement && this.bodyElement.classList.add("modal__body-wrapper");
    this.addModal(this.modalName);
  },

  destroyed() {
    this.bodyElement && this.bodyElement.classList.remove("modal__body-wrapper");
    this.removeModal(this.modalName);
  },
});
