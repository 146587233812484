









































































































































































































































































































import { defineComponent, PropType } from "@vue/composition-api";

export default defineComponent({
  name: "NoData",
  props: {
    noDataText: {
      type: String as PropType<string>,
      default: undefined,
    },
  },
});
