














































import { defineComponent, PropType } from "@vue/composition-api";

type Priority = "HIGHEST" | "HIGH" | "NORMAL" | "LOW" | "LOWEST";
enum PriorityEnum {
  HIGHEST = "Самый высокий приоритет",
  HIGH = "Высокий приоритет",
  NORMAL = "Средний приоритет",
  LOW = "Низкий приоритет",
  LOWEST = "Самый низкий приоритет",
}
const mapOfPriority = {
  HIGHEST: {
    rotate: "-rotate-90",
    color: "stalker",
    icon: "forward", 
  },
  HIGH: {
    rotate: "-rotate-0",
    color: "stalker",
    icon: "arrow-up", 
  },
  NORMAL: {
    rotate: "rotate-180",
    color: "snatch",
    icon: "status_med", 
  },
  LOW: {
    rotate: "rotate-70",
    color: "rocky",
    icon: "arrow-down", 
  },
  LOWEST: {
    rotate: "rotate-90",
    color: "rocky",
    icon: "forward", 
  },
};

export default defineComponent({
  name: "Priority",
  props: {
    priority: {
      type: String as PropType<Priority>,
      required: true,
    },
  },
  computed: {
    getStatus(): { rotate: string; color: string; icon: string } {
      const priority: Priority = this.priority;
      return mapOfPriority[priority];
    },
    getTooltipTitle(): string {
      const priority: Priority = this.priority;
      return PriorityEnum[priority];
    },
  },
});
