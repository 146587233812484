




































































import { computed, defineComponent } from "@vue/composition-api";


export default defineComponent({
  name: "TaskTableTooltip",
  props: {
    isArchived: {
      type: Boolean,
      default: false,
    },
    isPinned: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {

    const pinnedText = computed(() => props.isPinned? 'Открепить' : 'Закрепить');

    function handlePin(): void {
      emit("on-pin");
    }

    function handleEdit(): void {
      emit("on-edit");
    }

    function handleCopy(): void {
      emit("on-copy");
    }

    function handleZip(): void {
      emit("on-zip");
    }

    function handleUnZip(): void {
      emit("on-unzip");
    }

    return {
      handlePin,
      handleEdit,
      handleCopy,
      handleZip,
      handleUnZip,
      pinnedText,
    };
  },
});
