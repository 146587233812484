




























import { defineComponent } from "@vue/composition-api";

import Modal from "@/app/components/Base/Modal.vue";

export default defineComponent({
  name: "FilterModal",
  components: { Modal },
});
